import { Directive, Input } from '@angular/core';
import { CompanyStateService } from '@dougs/company/shared';
import { Operation, OperationType } from '@dougs/operations/dto';
import { AccountingProductsTourService } from '@dougs/revenue/products-tour/shared';
import { SynchronizedAccount } from '@dougs/synchronized-accounts/dto';
import { BaseOperationsComponentService } from '../../../services/base-operations-component.service';
import { OperationActionComponentService } from '../../../services/operation-action.component.service';
import { OperationsEcommerceCollectionActionsService } from '../../../services/operations-ecommerce-collection-actions.component.service';

@Directive({
  selector: 'dougs-base-operations',
  standalone: true,
})
export class BaseOperationsComponent {
  @Input() operationType!: OperationType;
  private _operations: Operation[] = [];
  @Input()
  set operations(operations: Operation[]) {
    this._operations = operations;
    if (operations) {
      this.updateCurrentOperations();
    }
  }

  get operations(): Operation[] {
    return this._operations;
  }

  @Input() openedOperationId?: number;
  @Input() synchronizedAccounts: SynchronizedAccount[] = [];
  @Input() listTitle!: string;
  @Input() showEcommerceActions = false;

  listIsReduced = false;
  currentOperations: Operation[] = [];

  constructor(
    protected readonly companyStateService: CompanyStateService,
    protected readonly operationActionComponentService: OperationActionComponentService,
    public readonly operationsEcommerceCollectionActionsService: OperationsEcommerceCollectionActionsService,
    public readonly baseOperationsServiceService: BaseOperationsComponentService,
    public readonly accountingProductsTourService: AccountingProductsTourService,
  ) {}

  trackByOperation(index: number, item: Operation) {
    return item.id;
  }

  onOpenOperationDetails(operationId: number): void {
    this.openedOperationId = this.openedOperationId === operationId ? undefined : operationId;
  }

  handleListReduction(): void {
    this.listIsReduced = !this.listIsReduced;
    this.updateCurrentOperations();
  }

  updateCurrentOperations(): void {
    if (this.listIsReduced) {
      this.currentOperations = [...this.operations].slice(0, 3);
    } else {
      this.currentOperations = [...this.operations];
    }
  }
}
