<ng-container *ngIf="operationModalComponentService.refreshOperation$ | async"></ng-container>
<ng-container *ngIf="operationModalComponentService.updateOperationFromSocket$ | async"></ng-container>
<div dougsModalTitle>
  <div class="search-header">
    <h6>Opération</h6>
  </div>
  <i class="fal fa-times ml-8 pointer" (click)="operationModalComponentService.onCloseModal()"></i>
</div>
<div dougsModalContent="xlarge" class="no-padding">
  <ng-container *ngIf="!!(modalOperationStateService.currentOperation$ | async); else loader">
    <dougs-operation
      [canToggleOperationDetails]="false"
      [showOperationDetails]="true"
      [changeUrlOnClick]="false"
      [isInOperationListModal]="true"
      [disableRefreshById]="true"
      [operation]="modalOperationStateService.currentOperation$ | async"
    ></dougs-operation>
  </ng-container>
  <ng-template #loader>
    <dougs-loader></dougs-loader>
  </ng-template>
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" (click)="operationModalComponentService.onCloseModal()">Fermer</dougs-button>
</div>
