import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormService } from '@dougs/core/form';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  DropdownComponent,
  DropdownOptionComponent,
  DropdownTriggerDirective,
  FormFieldComponent,
  InputDatepickerComponent,
  SelectComponent,
  SelectOptionComponent,
  TrackByPipe,
} from '@dougs/ds';
import { Currency } from '@dougs/synchronized-accounts/dto';
import { SynchronizedAccountStateService } from '@dougs/synchronized-accounts/shared';
import { ManualBankTransactionComponentService } from '../../services/manual-bank-transaction.component.service';

@Component({
  selector: 'dougs-manual-bank-transaction',
  templateUrl: './manual-bank-transaction.component.html',
  styleUrls: ['./manual-bank-transaction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    InputDatepickerComponent,
    ReactiveFormsModule,
    ControlFormFieldDirective,
    FormFieldComponent,
    SelectComponent,
    SelectOptionComponent,
    DropdownTriggerDirective,
    DropdownComponent,
    DropdownOptionComponent,
    ButtonComponent,
    CommonModule,
    TrackByPipe,
  ],
  providers: [ManualBankTransactionComponentService],
})
export class ManualBankTransactionComponent {
  currencyList = Currency;

  constructor(
    public readonly manualBankTransactionComponentService: ManualBankTransactionComponentService,
    public readonly formService: FormService,
    public readonly synchronizedAccountStateService: SynchronizedAccountStateService,
  ) {}
}
