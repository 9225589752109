import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ecommerceSalesCount',
  standalone: true,
})
export class EcommerceSalesCountPipe implements PipeTransform {
  transform(count: number): string {
    const plural: string = count > 1 ? 's' : '';
    return `${count} ventilation${plural}`;
  }
}
