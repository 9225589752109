<ng-container *ngIf="verifyOperationListModalComponentService.searchValueChanges$ | async"></ng-container>
<ng-container *ngIf="verifyOperationListModalComponentService.scrollChanges$ | async"></ng-container>
<ng-container *ngIf="verifyOperationListModalComponentService.resetOperationStateOnClose$ | async"></ng-container>
<div dougsModalTitle>
  <div class="search-header">
    <h6>Vérification des opérations - {{ data.title }}</h6>
  </div>
  <i class="fal fa-times ml-8" dougsModalClose></i>
</div>
<div
  (scrolled)="verifyOperationListModalComponentService.onScroll()"
  [immediateCheck]="true"
  [scrollWindow]="false"
  class="force-max-height no-padding"
  dougsModalContent="xlarge"
  infiniteScroll
>
  <div class="search-bar-container mb-24">
    <dougs-search-bar
      [formControl]="verifyOperationListModalComponentService.searchFormControl"
      [searchInProgress]="verifyOperationListModalComponentService.searchFormControl.value"
      (clearSearch)="verifyOperationListModalComponentService.onClearSearch()"
      size="small"
    ></dougs-search-bar>
  </div>
  <dougs-panel-info *ngIf="data.panelInfo" class="p-16 mb-16 mx-32">
    <span [innerHTML]="data.panelInfo"></span>
  </dougs-panel-info>
  <dougs-loader *ngIf="verifyOperationListModalComponentService.isLoading$ | async"></dougs-loader>
  <ng-container *ngIf="!(verifyOperationListModalComponentService.isLoading$ | async)">
    <ng-container *ngIf="(modalOperationsStateService.operations$ | async)?.length; else blankState">
      <div class="operation-list mx-32">
        <ng-container
          *ngFor="
            let operation of modalOperationsStateService.operations$ | async as operations;
            trackBy: 'id' | trackBy;
            let isFirst = first;
            let index = index
          "
        >
          <div
            *ngIf="operations | showOperationDivider: index : isFirst"
            class="px-24 py-8 operation-list__date operation-list__date__box-shadow"
          >
            {{ operation.date | date: 'MMMM yyyy' | titlecase }}
          </div>
          <dougs-operation
            [changeUrlOnClick]="false"
            [isInOperationListModal]="true"
            [operation]="operation"
            [synchronizedAccounts]="synchronizedAccountStateService.synchronizedAccounts$ | async"
          >
            <ng-template>
              <div class="operation-detail__actions">
                <dougs-button
                  [round]="true"
                  (click)="verifyOperationListModalComponentService.validateOperation($event, operation)"
                  [noWrap]="true"
                  [color]="operation.validated ? 'success' : 'primary'"
                  class="operation-detail__actions__button-validate"
                >
                  <span class="operation-detail__actions__button-validate__icon only-validate">
                    <i class="fal fa-check"></i>
                  </span>
                </dougs-button>
                <dougs-button
                  class="ml-8"
                  [color]="operation.manuallyLocked ? 'success' : 'primary'"
                  (click)="verifyOperationListModalComponentService.lockOperation($event, operation)"
                >
                  <span>
                    <i
                      class="far fa-lock"
                      [ngClass]="{ 'fa-lock': operation.manuallyLocked, 'fa-lock-open': !operation.manuallyLocked }"
                    ></i>
                  </span>
                </dougs-button>
              </div>
            </ng-template>
          </dougs-operation>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #blankState>
      <div *ngIf="!(verifyOperationListModalComponentService.isLoading$ | async)" class="mx-32">
        <dougs-blank-state>
          <h5>Aucune opération associée</h5>
        </dougs-blank-state>
      </div>
    </ng-template>
  </ng-container>
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
  <dougs-button (click)="verifyOperationListModalComponentService.verifyOperations()" color="primary">
    Vérification terminée &rarr;
  </dougs-button>
</div>
