import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonComponent } from '@dougs/ds';

@Component({
  selector: 'dougs-done-button',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './done-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoneButtonComponent {}
