import { ChangeDetectorRef, Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ResponsiveService } from '@dougs/core/responsive';

@Directive({
  selector: '[dougsOperationCompact]',
  exportAs: 'compactDirective',
  standalone: true,
})
export class OperationCompactDirective implements OnInit, OnDestroy {
  private observer!: ResizeObserver;
  private elementToObserve: HTMLElement | null = null;

  public isCompact = false;

  constructor(
    protected readonly host: ElementRef,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly responsiveService: ResponsiveService,
  ) {}

  ngOnInit(): void {
    this.observer = new ResizeObserver((entries) => {
      if (entries[0]) {
        const currentCompact: boolean = entries[0].target.clientWidth <= this.responsiveService.mobileWidth;
        if (currentCompact !== this.isCompact) {
          this.isCompact = currentCompact;
          this.cdr.markForCheck();
        }
      }
    });

    this.elementToObserve = this.host.nativeElement;
    if (this.elementToObserve) {
      this.observer.observe(this.elementToObserve);
    }
  }

  ngOnDestroy(): void {
    if (this.elementToObserve) {
      this.observer?.unobserve(this.elementToObserve);
    }
  }
}
