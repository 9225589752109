<div class="accrual-operation__cta">
  <dougs-button
    (click)="accrualOperationComponentService.updateOperationValidity(!operation.validated)"
    [color]="operation.validated ? 'secondary' : 'primary'"
    size="medium"
    [fullWidth]="true"
  >
    @if (accrualOperationComponentService.isUpdatingValidity$()) {
      <i
        class="fal fa-spinner-third fa-spin color-white mr-8"
        [ngClass]="{ 'color-white': !operation.validated, 'color-primary-700': operation.validated }"
      ></i>
    }
    @if (operation.validated) {
      <i class="fal fa-pen mr-8 color-primary-700"></i>
    }
    {{ operation.validated ? 'Editer la facture' : 'Valider la facture' }}
  </dougs-button>
</div>
