<div
  (click)="incomingOperationsComponentService.toggleList()"
  class="operation-list__header pointer px-16 py-8"
  [class.operation-list__header--open]="incomingOperationsComponentService.isListOpen$()"
>
  <i class="fal fa-clock mr-8"></i>
  <span>{{ incomingOperationsComponentService.operations$().length | ecommerceSalesCount }} à venir</span>
  <i [class.rotate]="incomingOperationsComponentService.isListOpen$()" class="fal fa-chevron-down"></i>
</div>
<div *ngIf="incomingOperationsComponentService.isListOpen$()" class="operation-list__content" @slideInOut>
  <dougs-operation
    *ngFor="let operation of incomingOperationsComponentService.operations$(); trackBy: 'id' | trackBy"
    [operation]="operation"
    [disableEdit]="true"
    [showOperationDetails]="incomingOperationsComponentService.openedOperationId$() === operation.id"
    (openOperationDetails)="incomingOperationsComponentService.openedOperationId = $event"
  >
    <ng-template>
      <div class="operation-detail__actions">
        <dougs-button
          [round]="true"
          [noWrap]="true"
          dougsTooltip="Cette opération pourra être validée à partir du mois suivant"
          color="primary"
          disabled="true"
          class="operation-detail__actions__button-validate"
        >
          <div class="operation-detail__actions__button-validate__container">
            <span class="operation-detail__actions__button-validate__icon mr-4">
              <i class="fas fa-check"></i>
            </span>
            <span class="operation-detail__actions__button-validate__text">Valider</span>
          </div>
        </dougs-button>
      </div>
    </ng-template>
  </dougs-operation>
</div>
