<ng-container *ngIf="filters$ | async"></ng-container>

<div dougsModalTitle>
  <div class="search-header">
    <h6>{{ data.title }}</h6>
  </div>
  <i class="fal fa-times ml-8" [dougsModalClose]="hasDoneOperationActions"></i>
</div>
<div
  (scrolled)="onScroll()"
  [immediateCheck]="true"
  [scrollWindow]="false"
  class="force-max-height no-padding"
  dougsModalContent="xlarge"
  infiniteScroll
>
  <div
    #searchbar
    class="search-bar-container"
    [ngClass]="{
      'pb-8': !!operationFilters,
      'pb-24': !operationFilters
    }"
  >
    <dougs-search-bar
      [formControl]="searchFormControl"
      [searchInProgress]="searchFormControl.value"
      (clearSearch)="onClearSearch()"
      size="small"
    ></dougs-search-bar>

    <div class="filters-container mx-32 pt-16 pb-8" *ngIf="operationFilters">
      <dougs-panel-info
        *ngIf="(hasValidatedAllOperations$ | async) && filtersFormControl?.value.date"
        type="success"
        class="dougs-panel-info mb-16"
      >
        <i class="fal fa-circle-check color-success mr-8"></i>
        <p class="small color-primary-700">
          Toutes les opérations de la période sont validées, vous pouvez finaliser votre déclaration.
        </p>
        <dougs-button color="secondary" class="ml-32" (click)="closeModal()"> Finaliser ma déclaration </dougs-button>
      </dougs-panel-info>

      <div class="filters">
        <label class="mr-8">Filtrer par :</label>
        <dougs-radio-group [formControl]="filtersFormControl">
          <dougs-radio *ngFor="let controlName of operationFilters" [value]="controlName.search">
            {{ controlName.message }}
          </dougs-radio>
        </dougs-radio-group>
      </div>
    </div>
  </div>

  <div
    #subtitle
    *ngIf="data.subtitle"
    class="subtitle__container p-16 mb-16 mx-32"
    [class.has-filters]="operationFilters?.length"
  >
    <div class="subtitle__container__left">
      <dougs-avatar size="large" *ngIf="data?.avatarImage" [image]="data.avatarImage"></dougs-avatar>
      <div>
        <div class="subtitle__container__left__text">
          <p class="small" *ngIf="data.preSubtitle">{{ data.preSubtitle }}&nbsp;</p>
          <h5>{{ data.subtitle }}</h5>
        </div>
        <p
          *ngIf="data?.additionalInfo"
          class="small"
          [innerHTML]="data.additionalInfo"
          [ngClass]="data?.additionalClass ?? ''"
        ></p>
      </div>
    </div>
    <div class="subtitle__container__right">
      <div *ngFor="let summaryValue of data.summaryValues" class="subtitle__container__right__values">
        <h5 class="color-primary" [ngClass]="summaryValue.colorClass ?? ''">
          <ng-container *ngIf="summaryValue?.customSuffix?.length">
            {{ summaryValue.value }}&nbsp;{{ summaryValue.customSuffix }}
          </ng-container>
          <ng-container *ngIf="!summaryValue?.customSuffix?.length">
            {{ summaryValue.value | currency: 'EUR' : 'symbol' : (summaryValue?.withDecimals ? '1.0-2' : '1.0-0') }}
          </ng-container>
        </h5>
        <p class="small color-gray" *ngIf="summaryValue?.text">{{ summaryValue.text }}</p>
      </div>
    </div>
  </div>
  <dougs-loader *ngIf="isLoading"></dougs-loader>
  <ng-container *ngIf="!isLoading">
    <ng-container *ngIf="(operations$ | async)?.length; else blankState">
      <div class="operation-list mx-32">
        <ng-container
          *ngFor="
            let operation of operations$ | async as operations;
            trackBy: 'id' | trackBy;
            let isFirst = first;
            let index = index
          "
        >
          <div
            *ngIf="operations | showOperationDivider: index : isFirst"
            [ngStyle]="{ top: (subtitleContainerHeight ?? 0) + (searchbarContainerHeight ?? 49) + 'px' }"
            class="px-24 py-8 operation-list__date operation-list__date__box-shadow"
          >
            {{ operation.date | date: 'MMMM yyyy' | titlecase }}
          </div>
          <dougs-operation
            [changeUrlOnClick]="false"
            [isInOperationListModal]="true"
            [mixpanelModalName]="data.mixpanelModalName"
            [operation]="operation"
            [synchronizedAccounts]="synchronizedAccountStateService.synchronizedAccounts$ | async"
            (emitOperationAction)="hasDoneOperationActions = true"
          ></dougs-operation>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #blankState>
      <div *ngIf="!isLoading" class="mx-32">
        <dougs-blank-state>
          <h5>Aucune opération associée</h5>
        </dougs-blank-state>
      </div>
    </ng-template>
  </ng-container>
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" [dougsModalClose]="hasDoneOperationActions">Fermer</dougs-button>
  <dougs-button [disabled]="!searchFormControl.value" *ngIf="data.submitButton" (click)="saveChart()">{{
    data.submitButton
  }}</dougs-button>
</div>
