import { Inject, Injectable } from '@angular/core';
import { StepActionType } from 'ngx-joyride';
import { JoyrideStepInfo } from 'ngx-joyride/lib/models/joyride-step-info.class';
import { combineLatest, concatMap, map, Observable } from 'rxjs';
import { AccountingOnboardingStateService } from '@dougs/accounting/onboarding/shared';
import { CompanyDemoStateService, CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import { toPromise } from '@dougs/core/utils';
import { AbstractProductTourService, ProductTourSteps } from './abstract-product-tour.service';

@Injectable({
  providedIn: 'root',
})
export class AccountingProductsTourService extends AbstractProductTourService {
  refreshAccountingOnboarding$ = this.companyStateService.activeCompanyIdChanged$.pipe(
    concatMap((activeCompany) => this.accountingOnboardingStateService.refreshAccountingOnboarding(activeCompany.id)),
  );

  mayShowCreationOnboarding$: Observable<boolean> = combineLatest([
    this.refreshAccountingOnboarding$,
    this.companyStateService.activeCompany$,
  ]).pipe(
    map(([onboarding, company]) => {
      return !!onboarding?.data.shouldShowCompanyCreationService && !company.siren;
    }),
  );

  constructor(
    @Inject(Window) private readonly window: Window,
    private readonly metricsService: MetricsService,
    private readonly companyStateService: CompanyStateService,
    private readonly companyDemoStateService: CompanyDemoStateService,
    private readonly accountingOnboardingStateService: AccountingOnboardingStateService,
  ) {
    super();
  }

  protected async callbackNextJoyride(joyrideStepInfo: JoyrideStepInfo): Promise<void> {
    setTimeout(() => {
      this.window.scroll(0, 0);
    }, 10);
    await this.sendProductTourEvent(joyrideStepInfo.number, 'Viewed', joyrideStepInfo);
  }

  protected async callbackErrorJoyride(err: Error, currentStep: number): Promise<void> {
    await this.sendProductTourEvent(currentStep, 'Error');
    this.logger.error(err);
  }

  protected async callbackCompleteJoyride(currentStep: number): Promise<void> {
    void this.userStateService.hasSeenProductTour();
    await this.sendProductTourEvent(currentStep, 'Closed');
  }

  private buildProductTourActionEvent(
    joyrideStepInfo: JoyrideStepInfo,
    productTourName: string,
    steps: ProductTourSteps,
  ): string | null {
    // condition means: not the beginning of the tour (step 1 action next is fired on tour start)
    if (joyrideStepInfo.number === 1 && joyrideStepInfo.actionType === StepActionType.NEXT) {
      return null;
    }
    let previousStep: number = joyrideStepInfo.number;
    switch (joyrideStepInfo.actionType) {
      case StepActionType.NEXT:
        previousStep--;
        break;
      case StepActionType.PREV:
        previousStep++;
        break;
      default: {
        const exhautivenessCheck: never = joyrideStepInfo.actionType;
        this.logger.error(`unknown StepActionType: ${exhautivenessCheck}`);
      }
    }

    const step: string = Object.keys(steps)[previousStep - 1];
    return [
      `${productTourName} Product Tour`,
      `Step ${step[0].toUpperCase() + step.slice(1)} ${
        joyrideStepInfo.actionType[0].toUpperCase() + joyrideStepInfo.actionType.slice(1).toLowerCase()
      }`,
      `Clicked`,
    ].join(' ');
  }

  private async sendProductTourEvent(
    currentStep: number,
    action: string,
    joyrideStepInfo?: JoyrideStepInfo,
  ): Promise<void> {
    const stepsList: ProductTourSteps | undefined = await toPromise(this.steps$);
    const productTourMode: string = (await toPromise(this.companyDemoStateService.hasDemoMode$)) ? 'Démo' : 'Réelle';
    const productTourName: string = this.companyStateService.activeCompany.siren
      ? `Accounting OAC Compta Synchro ${productTourMode}`
      : `Accounting OAC Créa Synchro ${productTourMode}`;

    if (joyrideStepInfo && stepsList) {
      const actionEvent: string | null = this.buildProductTourActionEvent(joyrideStepInfo, productTourName, stepsList);
      if (actionEvent) {
        this.metricsService.pushMixpanelEvent(actionEvent);
      }
    }

    if (stepsList) {
      const step: string = Object.keys(stepsList)[currentStep - 1];
      const event: string = [
        `${productTourName} Product Tour`,
        `Step ${step[0].toUpperCase() + step.slice(1)}`,
        `${action}`,
      ].join(' ');

      this.metricsService.pushMixpanelEvent(event, {
        joyrideStepInfo,
      });
    }
  }
}
