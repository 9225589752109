import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonComponent } from '@dougs/ds';

@Component({
  selector: 'dougs-next-button',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './next-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NextButtonComponent {}
