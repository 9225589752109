import { Inject, Injectable } from '@angular/core';
import { filter, Observable, Subject, withLatestFrom } from 'rxjs';
import { concatMap, debounceTime, map } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { Operation } from '@dougs/operations/dto';
import { AbstractAccountingOperationsStateService, OPERATION_STATE_TOKEN } from '@dougs/operations/shared';

const OPERATION_NUMBER_SCROLL_TRIGGER_LIMIT = 10;

@Injectable()
export class BaseOperationsComponentService {
  private readonly queueScroll: Subject<void> = new Subject<void>();
  private readonly queueScroll$ = this.queueScroll.asObservable();

  autoScrollOnOperationListChange$: Observable<void> = this.accountingOperationsStateService.operationsCount$.pipe(
    withLatestFrom(this.accountingOperationsStateService.operations$),
    filter(([_, operations]: [number, Operation[]]) =>
      operations.every((operation) => operation.companyId === this.companyStateService.activeCompany.id),
    ),
    map(([count, operations]: [number, Operation[]]) => {
      if (count > OPERATION_NUMBER_SCROLL_TRIGGER_LIMIT && operations?.length < OPERATION_NUMBER_SCROLL_TRIGGER_LIMIT) {
        this.queueScroll.next();
      }
    }),
  );

  loadMoreOperations$: Observable<void> = this.queueScroll$.pipe(
    debounceTime(10),
    concatMap(() =>
      this.accountingOperationsStateService.loadMoreOperations(this.companyStateService.activeCompany.id),
    ),
  );

  constructor(
    @Inject(OPERATION_STATE_TOKEN)
    protected readonly accountingOperationsStateService: AbstractAccountingOperationsStateService,
    protected readonly companyStateService: CompanyStateService,
  ) {}

  onScroll(): void {
    this.queueScroll.next();
  }
}
