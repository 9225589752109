import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ButtonComponent, ShowOperationDividerPipe, TooltipDirective, TrackByPipe } from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { IncomingOperationsStateService, OPERATION_STATE_TOKEN } from '@dougs/operations/shared';
import { EcommerceSalesCountPipe } from '../../../pipes/ecommerce-sales-count.pipe';
import { IncomingOperationsComponentService } from '../../../services/incoming-operations.component.service';
import { OperationComponent } from '../operation/operation.component';
import { OperationsEcommerceCollectionActionsComponent } from '../operations-ecommerce-collection-actions/operations-ecommerce-collection-actions.component';

const ANIMATION = '300ms ease-in-out';

@Component({
  selector: 'dougs-incoming-operations',
  standalone: true,
  imports: [
    CommonModule,
    ShowOperationDividerPipe,
    OperationsEcommerceCollectionActionsComponent,
    OperationComponent,
    InfiniteScrollModule,
    TrackByPipe,
    EcommerceSalesCountPipe,
    ButtonComponent,
    TooltipDirective,
  ],
  providers: [
    { provide: OPERATION_STATE_TOKEN, useExisting: IncomingOperationsStateService },
    IncomingOperationsComponentService,
  ],
  templateUrl: 'incoming-operations.component.html',
  styleUrls: ['incoming-operations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ height: 0, opacity: 0, overflow: 'hidden' }),
        animate(ANIMATION, style({ height: '*', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1, overflow: 'hidden' }),
        animate(ANIMATION, style({ height: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class IncomingOperationsComponent {
  constructor(public readonly incomingOperationsComponentService: IncomingOperationsComponentService) {}

  @Input()
  set operations(operations: Operation[]) {
    this.incomingOperationsComponentService.operations = operations;
  }
}
