import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ButtonComponent,
  LoaderComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';
import { ModalOperationStateService, OPERATION_STATE_TOKEN } from '@dougs/operations/shared';
import { OperationComponent } from '../../components/operations/operation/operation.component';
import { OperationModalComponentService } from '../../services/operation-modal.component.service';

@Component({
  selector: 'dougs-operation-modal.component.ts',
  standalone: true,
  imports: [
    CommonModule,
    ModalTitleDirective,
    ModalContentDirective,
    ModalFooterDirective,
    ModalCloseDirective,
    ButtonComponent,
    OperationComponent,
    LoaderComponent,
  ],
  templateUrl: './operation-modal.component.html',
  providers: [
    OperationModalComponentService,
    {
      provide: OPERATION_STATE_TOKEN,
      useExisting: ModalOperationStateService,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationModalComponent {
  constructor(
    public readonly operationModalComponentService: OperationModalComponentService,
    public readonly modalOperationStateService: ModalOperationStateService,
  ) {}
}
