import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { Operation } from '@dougs/operations/dto';

@Injectable()
export class IncomingOperationsComponentService {
  private readonly _operations$: WritableSignal<Operation[]> = signal<Operation[]>([]);
  private readonly _isListOpen$: WritableSignal<boolean> = signal<boolean>(false);
  private readonly _openedOperationId$: WritableSignal<number | undefined> = signal<number | undefined>(undefined);

  operations$: Signal<Operation[]> = this._operations$.asReadonly();
  isListOpen$: Signal<boolean> = this._isListOpen$.asReadonly();
  openedOperationId$: Signal<number | undefined> = this._openedOperationId$.asReadonly();

  set operations(operations: Operation[]) {
    this._operations$.set(operations);
  }

  set openedOperationId(openedOperationId: number | undefined) {
    this._openedOperationId$.set(openedOperationId);
  }

  toggleList(): void {
    this._isListOpen$.update((isOpen) => !isOpen);
  }
}
