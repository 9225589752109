<div class="accrual-operation__admin px-8 py-4">
  <div
    class="accrual-operation__admin__toggle pointer py-4"
    (click)="accrualOperationComponentService.toggleAdminPanel()"
  >
    <p class="tiny color-admin">Détails comptabilité</p>
    <i
      class="fal color-admin"
      [ngClass]="{
        'fa-chevron-up': accrualOperationComponentService.adminPanelOpened$(),
        'fa-chevron-down': !accrualOperationComponentService.adminPanelOpened$()
      }"
    ></i>
  </div>
  @if (accrualOperationComponentService.adminPanelOpened$()) {
    @if (operation?.accountingLines?.length) {
      <dougs-operation-details-accounting-lines [accountingLines]="operation.accountingLines" [isCompact]="true" />
    }
    <dougs-operation-details-actions [operation]="operation" [isCompact]="true" />
  }
</div>
