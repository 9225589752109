import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LoaderComponent, ModalCloseDirective, ModalContentDirective, ModalTitleDirective } from '@dougs/ds';

@Component({
  selector: 'dougs-validation-ecommerce-collection-loading-modal',
  templateUrl: './validation-ecommerce-collection-loading-modal.component.html',
  styleUrls: ['./validation-ecommerce-collection-loading-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ModalTitleDirective, ModalCloseDirective, ModalContentDirective, CommonModule, LoaderComponent],
})
export class ValidationEcommerceCollectionLoadingModalComponent {}
