<div id="counterTemplate">
  <ng-template #counterTemplate let-step="step" let-total="total">
    <dougs-counter-template *ngIf="step > 1" [step]="step" [total]="total"></dougs-counter-template>
  </ng-template>
</div>

<div id="doneButton">
  <ng-template #doneButton>
    <dougs-done-button></dougs-done-button>
  </ng-template>
</div>

<div id="nextButton">
  <ng-template #button #nextButton>
    <dougs-next-button></dougs-next-button>
  </ng-template>
</div>

<div id="nextFirstButton">
  <ng-template #button #nextFirstButton>
    <dougs-next-first-button></dougs-next-first-button>
  </ng-template>
</div>

<div id="previousButton">
  <ng-template #previousButton>
    <dougs-previous-button></dougs-previous-button>
  </ng-template>
</div>

<div id="welcome-demo">
  <ng-template #content>
    <dougs-product-tour-template-welcome-demo></dougs-product-tour-template-welcome-demo>
  </ng-template>
</div>

<div id="operation">
  <ng-template #content>
    <dougs-product-tour-template-operation></dougs-product-tour-template-operation>
  </ng-template>
</div>

<div id="shortcut">
  <ng-template #content>
    <dougs-product-tour-template-shortcut></dougs-product-tour-template-shortcut>
  </ng-template>
</div>

<div id="creation-meeting">
  <ng-template #content>
    <dougs-product-tour-template-creation-meeting></dougs-product-tour-template-creation-meeting>
  </ng-template>
</div>

<div id="end">
  <ng-template #content #end>
    <dougs-product-tour-template-end></dougs-product-tour-template-end>
  </ng-template>
</div>

<div
  joyrideStep="end"
  [stepContent]="end"
  stepPosition="center"
  [counterTemplate]="counterTemplate"
  [doneTemplate]="doneButton"
  [prevTemplate]="previousButton"
></div>
