import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CompanyStateService } from '@dougs/company/shared';
import { DougsDatePipe, ShowOperationDividerPipe } from '@dougs/ds';
import { NeedsAttentionOperationsStateService, OPERATION_STATE_TOKEN } from '@dougs/operations/shared';
import { AccountingProductsTourService } from '@dougs/revenue/products-tour/shared';
import { BaseOperationsComponentService } from '../../../services/base-operations-component.service';
import { OperationActionComponentService } from '../../../services/operation-action.component.service';
import { OperationsEcommerceCollectionActionsService } from '../../../services/operations-ecommerce-collection-actions.component.service';
import { BaseOperationsComponent } from '../base-operations/base-operations.component';
import { OperationComponent } from '../operation/operation.component';
import { OperationsEcommerceCollectionActionsComponent } from '../operations-ecommerce-collection-actions/operations-ecommerce-collection-actions.component';

@Component({
  selector: 'dougs-needs-attention-operations',
  templateUrl: '../base-operations/base-operations.template.html',
  styleUrls: ['../base-operations/base-operations.template.scss'],
  providers: [
    { provide: OPERATION_STATE_TOKEN, useExisting: NeedsAttentionOperationsStateService },
    BaseOperationsComponentService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ShowOperationDividerPipe,
    OperationsEcommerceCollectionActionsComponent,
    OperationComponent,
    InfiniteScrollModule,
    DougsDatePipe,
  ],
})
export class NeedsAttentionOperationsComponent extends BaseOperationsComponent {
  constructor(
    protected readonly companyStateService: CompanyStateService,
    protected readonly operationActionComponentService: OperationActionComponentService,
    public readonly operationsEcommerceCollectionActionsService: OperationsEcommerceCollectionActionsService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly elementRef: ElementRef,
    protected readonly window: Window,
    public readonly baseOperationsComponentService: BaseOperationsComponentService,
    public readonly accountingProductsTourService: AccountingProductsTourService,
  ) {
    super(
      companyStateService,
      operationActionComponentService,
      operationsEcommerceCollectionActionsService,
      baseOperationsComponentService,
      accountingProductsTourService,
    );
  }
}
