import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Operation } from '@dougs/operations/dto';
import { AccrualOperationComponentService } from '../../../../services/accrual-operation.component.service';
import { OperationDetailsAccountingLinesComponent } from '../../operation/operation-details/operation-details-accounting-lines/operation-details-accounting-lines.component';
import { OperationDetailsActionsComponent } from '../../operation/operation-details/operation-details-actions/operation-details-actions.component';

@Component({
  selector: 'dougs-accrual-operation-admin',
  standalone: true,
  imports: [CommonModule, OperationDetailsAccountingLinesComponent, OperationDetailsActionsComponent],
  templateUrl: './accrual-operation-admin.component.html',
  styleUrl: './accrual-operation-admin.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccrualOperationAdminComponent {
  constructor(public readonly accrualOperationComponentService: AccrualOperationComponentService) {}

  @Input({ required: true }) operation!: Operation;
}
