import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProductTourTemplateShortcutComponentService } from '../../../services/product-tour-templates/product-tour-template-shortcut.component.service';

@Component({
  selector: 'dougs-product-tour-template-shortcut',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './product-tour-template-shortcut.component.html',
  styleUrls: ['../product-tour-template.common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProductTourTemplateShortcutComponentService],
})
export class ProductTourTemplateShortcutComponent {
  constructor(
    public readonly productTourTemplateShortcutComponentService: ProductTourTemplateShortcutComponentService,
  ) {}
}
