import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';

@Injectable()
export class ProductTourTemplateShortcutComponentService {
  buttonList$: Observable<string> = this.companyStateService.activeCompany$.pipe(
    map((company) => {
      const button: string[] = ['notes de frais'];

      if (company.legalFormConfig.allowKilometricIndemnity) {
        button.push('indemnités kilométriques');
      }
      if (company.legalFormConfig.allowAssociateAccount) {
        button.push('recettes en espèces');
      }

      if (button.length > 1) {
        const lastButton: string | undefined = button.pop();
        return button.join(', ') + ' ou ' + lastButton;
      }
      return button[0];
    }),
  );

  constructor(private readonly companyStateService: CompanyStateService) {}
}
