import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PillComponent } from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { InvoiceOperationStatusPillPipe } from '../../../../pipes/invoice-operation-status-pill.pipe';
import { InvoiceOperationStatusPipe } from '../../../../pipes/invoice-operation-status.pipe';
import { AccrualOperationCustomerComponentService } from '../../../../services/accrual-operation-customer.component.service';
import { AccrualOperationSupplierComponentService } from '../../../../services/accrual-operation-supplier.component.service';
import { AccrualOperationComponentService } from '../../../../services/accrual-operation.component.service';

@Component({
  selector: 'dougs-accrual-operation-card',
  standalone: true,
  imports: [CommonModule, InvoiceOperationStatusPillPipe, InvoiceOperationStatusPipe, PillComponent],
  templateUrl: './accrual-operation-card.component.html',
  styleUrl: './accrual-operation-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccrualOperationCardComponent {
  constructor(
    public readonly accrualOperationComponentService: AccrualOperationComponentService,
    public readonly accrualOperationSupplierComponentService: AccrualOperationSupplierComponentService,
    public readonly accrualOperationCustomerComponentService: AccrualOperationCustomerComponentService,
  ) {}

  @Input({ required: true }) operation!: Operation;
}
