import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CompanyDemoStateService, CompanyStateService } from '@dougs/company/shared';
import { AccountingProductsTourService } from '@dougs/revenue/products-tour/shared';
import { ProductTourDirective } from '../../directives/product-tour.directive';
import { ProductTourAccountingComponent } from '../product-tour-accounting/product-tour-accounting.component';
import { ProductTourAccountingDemoComponent } from '../product-tour-accounting-demo/product-tour-accounting-demo.component';
import { ProductTourAccountingWithoutSirenComponent } from '../product-tour-accounting-without-siren/product-tour-accounting-without-siren.component';
import { ProductTourAccountingWithoutSirenDemoComponent } from '../product-tour-accounting-without-siren-demo/product-tour-accounting-without-siren-demo.component';

@Component({
  selector: 'dougs-product-tour',
  standalone: true,
  imports: [
    CommonModule,
    ProductTourDirective,
    ProductTourAccountingComponent,
    ProductTourAccountingDemoComponent,
    ProductTourAccountingWithoutSirenComponent,
    ProductTourAccountingWithoutSirenDemoComponent,
  ],
  templateUrl: './product-tour.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductTourComponent {
  constructor(
    public readonly accountingProductsTourService: AccountingProductsTourService,
    public readonly companyStateService: CompanyStateService,
    public readonly companyDemoStateService: CompanyDemoStateService,
  ) {
    setTimeout(async () => {
      await this.accountingProductsTourService.startTour();
    }, 1000);
  }
}
