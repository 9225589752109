import { Directive, Input, TemplateRef } from '@angular/core';
import { JoyrideDirective } from 'ngx-joyride';

@Directive({
  selector: '[productTour]',
  standalone: true,
})
export class ProductTourDirective extends JoyrideDirective {
  @Input()
  set productTourContent(stepContent: TemplateRef<any> | undefined) {
    this.stepContent = stepContent;
    this.ngAfterViewInit();
  }

  @Input()
  set productTourNextTemplate(nextTemplate: TemplateRef<any> | undefined) {
    this.nextTemplate = nextTemplate;
    this.ngAfterViewInit();
  }
}
