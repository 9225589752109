import { Inject, Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, first, from, lastValueFrom, Observable, Subject } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { HelpModalComponent, MODAL_DATA, ModalRef, ModalService, OverlayCloseEvent } from '@dougs/ds';
import { AccountingSearch, Operation } from '@dougs/operations/dto';
import { ModalOperationsStateService } from '@dougs/operations/shared';
import { SynchronizedAccountStateService } from '@dougs/synchronized-accounts/shared';

@Injectable()
export class VerifyOperationListModalComponentService {
  constructor(
    @Inject(MODAL_DATA)
    public data: {
      search: AccountingSearch;
      title: string;
    },
    public readonly modalOperationsStateService: ModalOperationsStateService,
    private readonly companyStateService: CompanyStateService,
    public readonly synchronizedAccountStateService: SynchronizedAccountStateService,
    public readonly modalService: ModalService,
    private readonly modalRef: ModalRef,
  ) {}

  get operationSearch(): AccountingSearch {
    return this.data.search;
  }

  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isLoading$: Observable<boolean> = this.isLoading.asObservable();
  public searchFormControl: UntypedFormControl = new UntypedFormControl();
  public hasDoneOperationActions = false;

  private readonly queueScroll: Subject<void> = new Subject<void>();
  private readonly queueScroll$ = this.queueScroll.asObservable();
  public searchValueChanges$!: Observable<void>;
  public scrollChanges$!: Observable<void>;
  public resetOperationStateOnClose$!: Observable<OverlayCloseEvent<unknown>>;
  private offset = 0;

  async initialize(): Promise<void> {
    this.isLoading.next(true);
    await this.modalOperationsStateService.refreshOperations(
      this.companyStateService.activeCompany.id,
      this.operationSearch,
      true,
    );
    this.isLoading.next(false);
    this.searchValueChanges$ = this.searchFormControl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(500),
      tap((searchValue: string) => this.initSearching(searchValue)),
      concatMap(() =>
        this.modalOperationsStateService.refreshOperations(
          this.companyStateService.activeCompany.id,
          this.operationSearch,
          true,
        ),
      ),
      tap(() => this.endSearching()),
    );

    this.resetOperationStateOnClose$ = this.modalRef.beforeClosed$.pipe(
      tap(() => this.modalOperationsStateService.resetStateOperations()),
    );

    this.scrollChanges$ = this.queueScroll$.pipe(
      debounceTime(10),
      tap(() => this.offset++),
      concatMap(() =>
        from(
          this.modalOperationsStateService.loadMoreOperations(
            this.companyStateService.activeCompany.id,
            this.operationSearch,
            this.offset,
            true,
          ),
        ),
      ),
    );
  }

  onScroll() {
    this.queueScroll.next();
  }

  onClearSearch(): void {
    this.searchFormControl.reset('');
  }

  private initSearching(searchValue: string): void {
    this.isLoading.next(true);
    this.offset = 0;
    this.operationSearch.search = searchValue;
  }

  private endSearching(): void {
    this.isLoading.next(false);
  }

  public async verifyOperations(): Promise<void> {
    const operations: Operation[] = await lastValueFrom(this.modalOperationsStateService.operations$.pipe(first()));
    if (operations.filter((operation) => !operation.manuallyLocked || !operation.validated)?.length > 0) {
      await lastValueFrom(
        this.modalService.open(HelpModalComponent, {
          data: {
            title: 'Vérification non finalisée',
            body: `Pour finaliser la vérification, toutes les opérations doivent être validées et verrouillées.`,
          },
        }).afterClosed$,
      );
    } else {
      this.modalRef.close(true);
    }
  }

  async validateOperation(event: MouseEvent, operation: Operation): Promise<void> {
    event.stopPropagation();
    const updatedOperation: Operation = { ...operation, validated: !operation.validated };
    await this.modalOperationsStateService.validateOperation(updatedOperation);
  }

  async lockOperation(event: MouseEvent, operation: Operation): Promise<void> {
    event.stopPropagation();
    const updatedOperation: Operation = { ...operation, manuallyLocked: !operation.manuallyLocked };
    await this.modalOperationsStateService.updateOperation(updatedOperation);
  }
}
