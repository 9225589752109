import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CustomerStateService } from '@dougs/customers/shared';
import {
  BlurOnEnterDirective,
  CheckboxComponent,
  ControlFormFieldDirective,
  CustomSelectOptionFooterDirective,
  DividerComponent,
  FormFieldComponent,
  InputDatepickerComponent,
  LabelFormFieldDirective,
  SelectComponent,
  SelectOptionComponent,
  SuffixFormFieldDirective,
  TooltipDirective,
} from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { SupplierStateService } from '@dougs/suppliers/shared';
import { AccrualOperationCustomerComponentService } from '../../../../services/accrual-operation-customer.component.service';
import { AccrualOperationSupplierComponentService } from '../../../../services/accrual-operation-supplier.component.service';
import { AccrualOperationComponentService } from '../../../../services/accrual-operation.component.service';
import { BreakdownService } from '../../../../services/breakdown.service';
import { OperationBreakdownComponent } from '../../operation/operation-breakdown/operation-breakdown.component';
import { OperationVatComponent } from '../../operation/operation-vat/operation-vat.component';

@Component({
  selector: 'dougs-accrual-operation-form',
  standalone: true,
  imports: [
    CommonModule,
    BlurOnEnterDirective,
    ControlFormFieldDirective,
    CustomSelectOptionFooterDirective,
    DividerComponent,
    FormFieldComponent,
    FormsModule,
    InputDatepickerComponent,
    LabelFormFieldDirective,
    OperationBreakdownComponent,
    OperationVatComponent,
    SelectComponent,
    SelectOptionComponent,
    SuffixFormFieldDirective,
    TooltipDirective,
    CheckboxComponent,
  ],
  templateUrl: './accrual-operation-form.component.html',
  styleUrl: './accrual-operation-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccrualOperationFormComponent {
  constructor(
    public readonly accrualOperationComponentService: AccrualOperationComponentService,
    public readonly accrualOperationSupplierComponentService: AccrualOperationSupplierComponentService,
    public readonly accrualOperationCustomerComponentService: AccrualOperationCustomerComponentService,
    public readonly breakdownService: BreakdownService,
    public readonly supplierStateService: SupplierStateService,
    public readonly customerStateService: CustomerStateService,
  ) {}

  @Input({ required: true }) operation!: Operation;

  @ViewChild('supplierInput') set supplierInput(supplierInput: SelectComponent) {
    this.accrualOperationSupplierComponentService.setSupplierInput(supplierInput);
  }

  @ViewChild('customerInput') set customerInput(customerInput: SelectComponent) {
    this.accrualOperationCustomerComponentService.setCustomerInput(customerInput);
  }
}
