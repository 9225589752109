import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonComponent } from '@dougs/ds';
import { NotValidatedOperationsStateService, OperationsSearchService } from '@dougs/operations/shared';
import { OperationsEcommerceCollectionActionsService } from '../../../services/operations-ecommerce-collection-actions.component.service';

@Component({
  selector: 'dougs-operations-ecommerce-collection-actions',
  templateUrl: './operations-ecommerce-collection-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ButtonComponent],
})
export class OperationsEcommerceCollectionActionsComponent {
  constructor(
    public readonly operationsEcommerceCollectionActionsService: OperationsEcommerceCollectionActionsService,
    public readonly operationsSearchService: OperationsSearchService,
    public readonly notValidatedOperationsStateService: NotValidatedOperationsStateService,
  ) {}
}
