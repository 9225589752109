import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'dougs-product-tour-template-welcome',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './product-tour-template-welcome.component.html',
  styleUrls: ['../product-tour-template.common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductTourTemplateWelcomeComponent {}
