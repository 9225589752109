@if (accrualOperationSupplierComponentService.refreshSuppliers$ | async) {}
@if (accrualOperationSupplierComponentService.updateSelectedSupplier$ | async) {}
@if (accrualOperationCustomerComponentService.refreshCustomers$ | async) {}
@if (accrualOperationCustomerComponentService.updateSelectedCustomer$ | async) {}

@if (accrualOperationComponentService.operation$(); as operation) {
  <div class="accrual-operation__container p-16">
    @if (!operation.validated) {
      <dougs-accrual-operation-form [operation]="operation" />
    } @else {
      <dougs-accrual-operation-card [operation]="operation" />
    }
    @if ((userStateService.loggedInUser$ | async)?.isAccountantOrAdmin) {
      <dougs-accrual-operation-admin [operation]="operation" />
    }
    <dougs-accrual-operation-cta [operation]="operation" />
  </div>
}
