import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {
  BlankStateComponent,
  ButtonComponent,
  DougsDatePipe,
  LoaderComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  PanelInfoComponent,
  SearchBarComponent,
  ShowOperationDividerPipe,
  TrackByPipe,
} from '@dougs/ds';
import { AccountingSearch } from '@dougs/operations/dto';
import { ModalOperationsStateService, OPERATION_STATE_TOKEN } from '@dougs/operations/shared';
import { SynchronizedAccountStateService } from '@dougs/synchronized-accounts/shared';
import { OperationComponent } from '../../components/operations/operation/operation.component';
import { VerifyOperationListModalComponentService } from '../../services/verify-operation-list-modal.component.service';

@Component({
  selector: 'dougs-verify-operation-list-modal',
  templateUrl: './verify-operation-list-modal.component.html',
  styleUrls: ['./verify-operation-list-modal.component.scss'],
  providers: [
    {
      provide: OPERATION_STATE_TOKEN,
      useExisting: ModalOperationsStateService,
    },
    VerifyOperationListModalComponentService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    ModalFooterDirective,
    CommonModule,
    InfiniteScrollModule,
    SearchBarComponent,
    ReactiveFormsModule,
    PanelInfoComponent,
    LoaderComponent,
    TrackByPipe,
    ShowOperationDividerPipe,
    OperationComponent,
    ButtonComponent,
    BlankStateComponent,
    DougsDatePipe,
  ],
})
export class VerifyOperationListModalComponent implements OnInit {
  constructor(
    @Inject(MODAL_DATA)
    public data: {
      search: AccountingSearch;
      title: string;
      panelInfo: string;
    },
    public readonly modalOperationsStateService: ModalOperationsStateService,
    public readonly synchronizedAccountStateService: SynchronizedAccountStateService,
    public verifyOperationListModalComponentService: VerifyOperationListModalComponentService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.verifyOperationListModalComponentService.initialize();
  }
}
