import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'dougs-counter-template',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './counter-template.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CounterTemplateComponent {
  @Input() step!: number;
  @Input() total!: number;
}
