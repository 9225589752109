<dougs-product-tour-accounting
  *ngIf="
    !(accountingProductsTourService.mayShowCreationOnboarding$ | async) &&
    !(companyDemoStateService.hasDemoMode$ | async)
  "
></dougs-product-tour-accounting>
<dougs-product-tour-accounting-demo
  *ngIf="
    !(accountingProductsTourService.mayShowCreationOnboarding$ | async) && companyDemoStateService.hasDemoMode$ | async
  "
></dougs-product-tour-accounting-demo>
<dougs-product-tour-accounting-without-siren
  *ngIf="
    (accountingProductsTourService.mayShowCreationOnboarding$ | async) &&
    !(companyDemoStateService.hasDemoMode$ | async)
  "
></dougs-product-tour-accounting-without-siren>
<dougs-product-tour-accounting-without-siren-demo
  *ngIf="
    (accountingProductsTourService.mayShowCreationOnboarding$ | async) && companyDemoStateService.hasDemoMode$ | async
  "
></dougs-product-tour-accounting-without-siren-demo>
