<h5 class="product-tour-title">
  <img class="mr-8" src="images/emoji/waving-hand.png" alt="Welcome" />
  Bienvenue à bord
</h5>
<p class="mb-24">
  Bravo ! Votre compte démo est activé et vous pouvez dès à présent commencer à tester les fonctionnalités de Dougs.
</p>
<p>
  Synchronisez le compte bancaire de votre société pour voir vos vraies opérations et démarrer le suivi réel de votre
  comptabilité.
</p>
