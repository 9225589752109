<dougs-button
  *ngIf="!(operationsSearchService.hasCurrentSearch$ | async)"
  color="primary-light"
  (click)="operationsEcommerceCollectionActionsService.shouldShowEcommerceCollectionButtons()"
  noWrap="true"
>
  <i class="mr-8 fas fa-check"></i>
  Validation des encaissements e-commerce
</dougs-button>
<dougs-button
  *ngIf="operationsEcommerceCollectionActionsService.hasEcommerceCollectionSearch$ | async"
  class="mr-8"
  color="primary"
  [disabled]="!(notValidatedOperationsStateService.operations$ | async)?.length"
  (click)="operationsEcommerceCollectionActionsService.submitEcommerceCollectionValidation()"
>
  <i class="mr-8 fas fa-check"></i>
  Valider les opérations
</dougs-button>
<dougs-button
  *ngIf="operationsEcommerceCollectionActionsService.hasEcommerceCollectionSearch$ | async"
  color="primary-light"
  (click)="operationsEcommerceCollectionActionsService.shouldNotShowEcommerceCollectionButtons()"
>
  <i class="mr-8 fas fa-times"></i>
  Quitter la validation
</dougs-button>
