import { Inject, Injectable } from '@angular/core';
import { concatMap, first, lastValueFrom, Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CompanyChangedStateService } from '@dougs/core/socket';
import { MODAL_DATA, ModalRef } from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { ModalOperationStateService, SocketOperationsStateService } from '@dougs/operations/shared';

@Injectable()
export class OperationModalComponentService {
  private readonly setCurrentOperationSubject: ReplaySubject<number> = new ReplaySubject<number>(1);
  public setCurrentOperation$: Observable<number> = this.setCurrentOperationSubject.asObservable();
  public initialOperation!: Operation;

  refreshOperation$: Observable<Operation> = this.setCurrentOperation$.pipe(
    concatMap((operationId: number) =>
      this.modalOperationStateService.refreshOperationById(this.data.companyId, operationId),
    ),
    tap((operation: Operation) => (this.initialOperation = operation)),
    tap((operation: Operation) => this.modalOperationStateService.updateCurrentOperationState(operation)),
  );

  updateOperationFromSocket$: Observable<void> = this.companyChangedStateService.companyChanged$.pipe(
    concatMap((companyChanged) => this.socketOperationsStateService.updateOperationFromSocket(companyChanged)),
  );

  constructor(
    @Inject(MODAL_DATA) public data: { operationId: number; companyId: number },
    private readonly modalOperationStateService: ModalOperationStateService,
    private readonly modalRef: ModalRef,
    private readonly companyChangedStateService: CompanyChangedStateService,
    private readonly socketOperationsStateService: SocketOperationsStateService,
  ) {
    this.setCurrentOperationSubject.next(this.data.operationId);
  }

  async onCloseModal(): Promise<void> {
    const currentOperation: Operation | null = await lastValueFrom(
      this.modalOperationStateService.currentOperation$.pipe(first()),
    );
    const operationHasBeenUpdated: boolean = this.initialOperation.updatedAt !== currentOperation?.updatedAt;
    this.modalRef.close({ operationHasBeenUpdated });
  }
}
