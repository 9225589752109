import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { combineLatest, concatMap, filter, lastValueFrom, Observable, tap } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { Customer } from '@dougs/customers/dto';
import { CustomerStateService } from '@dougs/customers/shared';
import { ModalService, SelectComponent } from '@dougs/ds';
import { AssociationSlot, Operation } from '@dougs/operations/dto';
import { AccrualOperationComponentService } from './accrual-operation.component.service';
import { OperationComponentService } from './operation.component.service';

@Injectable()
export class AccrualOperationCustomerComponentService {
  constructor(
    private readonly modalService: ModalService,
    private readonly companyStateService: CompanyStateService,
    private readonly customerStateService: CustomerStateService,
    private readonly operationComponentService: OperationComponentService,
    private readonly accrualOperationComponentService: AccrualOperationComponentService,
  ) {}

  loadingCustomer: WritableSignal<boolean> = signal<boolean>(true);
  loadingCustomer$: Signal<boolean> = this.loadingCustomer.asReadonly();

  selectedCustomer: WritableSignal<Customer | null> = signal<Customer | null>(null);
  selectedCustomer$: Signal<Customer | null> = this.selectedCustomer.asReadonly();
  showCustomerInput$: Signal<boolean> = computed(() => !!this.customerAssociationSlot$() && !this.loadingCustomer$());

  customerAssociationSlot$: Signal<AssociationSlot | null> = computed(
    () =>
      this.accrualOperationComponentService
        .counterpartBreakdown$()
        ?.associations?.find((association) => association.name === 'customer')?.slots?.['customer'] || null,
  );

  invoiceAndCustomerAssociationSlot$: Signal<AssociationSlot | null> = computed(
    () =>
      this.accrualOperationComponentService
        .mainBreakdown$()
        ?.associations?.find((association) => association.name === 'invoiceAndCustomer')?.slots?.['customer'] || null,
  );

  customerName$: Signal<string | null> = computed(
    () =>
      this.customerAssociationSlot$()?.selectedItem?.label ||
      this.invoiceAndCustomerAssociationSlot$()?.selectedItem?.label ||
      null,
  );

  customerInput?: SelectComponent;

  updateSelectedCustomer$: Observable<void> = combineLatest([
    toObservable(this.customerAssociationSlot$),
    this.customerStateService.customers$,
  ]).pipe(
    filter((object): object is [AssociationSlot, Customer[]] => !!object?.[0] && !!object?.[1]),
    map(([associationSlot, customers]) =>
      customers.find((customer) => customer.id === associationSlot.selectedItem?.value),
    ),
    map((customer) => this.selectedCustomer.set(customer ?? null)),
  );

  refreshCustomers$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    switchMap((company) =>
      this.accrualOperationComponentService.operationIdChanged$.pipe(
        map((operation) => [company, operation] as [Company, Operation]),
      ),
    ),
    filter(([_, operation]: [Company, Operation | null]) => operation?.type === 'invoiceCustomer'),
    map(([company, _]) => company),
    tap(() => this.loadingCustomer.set(true)),
    concatMap((company) => this.customerStateService.refreshCustomers(company.id)),
    tap(() => this.loadingCustomer.set(false)),
  );

  async createCustomer(e: Event): Promise<void> {
    e.preventDefault();

    const { CustomerCreationModalComponent } = await import('@dougs/customers/ui');
    const customerCreated: Customer | null | undefined = (
      await lastValueFrom(this.modalService.open<Customer>(CustomerCreationModalComponent).afterClosed$)
    ).data;
    if (customerCreated) {
      this.selectCustomer(customerCreated);
      this.customerInput?.hideDropdown();
    }
  }

  selectCustomer(customer: Customer): void {
    this.selectedCustomer.set(customer);

    const associationSlot: AssociationSlot | null = this.customerAssociationSlot$();
    if (associationSlot) {
      this.operationComponentService.updateAssociation(
        this.accrualOperationComponentService.counterpartBreakdown$(),
        associationSlot,
        customer.id,
      );
    }
  }

  setCustomerInput(customerInput: SelectComponent): void {
    this.customerInput = customerInput;
  }
}
