import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, first, forkJoin, lastValueFrom, map, Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import { ConfirmationModalComponent, FlashMessagesService, HelpModalComponent, ModalService } from '@dougs/ds';
import { AccountingSearch, Operation } from '@dougs/operations/dto';
import {
  EcommerceOperationsState,
  NeedsAttentionOperationsStateService,
  NotValidatedOperationsStateService,
  OperationsSearchService,
  ValidatedOperationsStateService,
} from '@dougs/operations/shared';
import { ValidationEcommerceCollectionLoadingModalComponent } from '../modals/validation-ecommerce-collection-loading-modal/validation-ecommerce-collection-loading-modal.component';

@Injectable()
export class OperationsEcommerceCollectionActionsService {
  private readonly shouldFilterOperationSubject: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(
    null,
  );
  readonly shouldFilterOperations$: Observable<boolean | null> = this.shouldFilterOperationSubject.asObservable();

  hasEcommerceCollectionSearch$: Observable<boolean | undefined> = this.operationsSearchService.currentSearch$.pipe(
    map((search) => search?.ecommerceCollection),
  );

  shouldShowEcommerceCollectionButtons$: Observable<boolean> = this.companyStateService.activeCompany$.pipe(
    map((activeCompany: Company) => {
      return activeCompany.accountingConfiguration?.hasEcommerceDispatch;
    }),
  );

  shouldShowBlankSlate$: Observable<boolean> = combineLatest([
    this.notValidatedOperationsStateService.operations$,
    this.operationsSearchService.hasCurrentSearch$,
  ]).pipe(
    map(([notValidatedOperations, hasCurrentSearch]: [Operation[], boolean]) => {
      return notValidatedOperations?.length === 0 && hasCurrentSearch;
    }),
  );

  constructor(
    private readonly ecommerceOperationsState: EcommerceOperationsState,
    private readonly operationsSearchService: OperationsSearchService,
    private readonly notValidatedOperationsStateService: NotValidatedOperationsStateService,
    private readonly validatedOperationsStateService: ValidatedOperationsStateService,
    private readonly needsAttentionOperationsStateService: NeedsAttentionOperationsStateService,
    public readonly metricsService: MetricsService,
    public readonly modalService: ModalService,
    public readonly companyStateService: CompanyStateService,
    public readonly flashMessagesService: FlashMessagesService,
  ) {}

  async submitEcommerceCollectionValidation(): Promise<void> {
    this.metricsService.pushMixpanelEvent('Operations Submit Validation Ecommerce Collection', {
      'CTA Location': 'Operations',
    });
    const isConfirmed = await this.openConfirmationModal();

    if (isConfirmed) {
      this.metricsService.pushMixpanelEvent('Accounting e-commerce collection validation validated', {
        'CTA Location': 'Operations',
      });
      const loadingModal = this.modalService.open(ValidationEcommerceCollectionLoadingModalComponent);
      const currentSearch: AccountingSearch = await lastValueFrom(
        this.operationsSearchService.currentSearch$.pipe(first()),
      );
      const jobExecuted = await this.ecommerceOperationsState.validateEcommerceCollectionOperations(
        this.companyStateService.activeCompany.id,
        currentSearch,
      );
      if (jobExecuted) {
        await lastValueFrom(
          forkJoin([
            this.notValidatedOperationsStateService.refreshOperations(this.companyStateService.activeCompany.id),
            this.validatedOperationsStateService.refreshOperations(this.companyStateService.activeCompany.id),
            this.needsAttentionOperationsStateService.refreshOperations(this.companyStateService.activeCompany.id),
          ]),
        );
      }
      loadingModal.close();

      if (jobExecuted?.metadata.warnings?.length) {
        this.metricsService.pushMixpanelEvent('Accounting e-commerce collection validation result', {
          'Result Type': 'Partial Validation',
        });
        this.openIncompleteValidationOperationsModal();
      } else if (!jobExecuted?.metadata.error) {
        this.metricsService.pushMixpanelEvent('Accounting e-commerce collection validation result', {
          'Result Type': 'Validated',
        });
        this.flashMessagesService.show('Vos opérations e-commerce sont validées', {
          type: 'success',
          timeout: 5000,
        });
      }
    }
  }

  private openIncompleteValidationOperationsModal(): void {
    this.modalService.open(HelpModalComponent, {
      data: {
        title: 'Validation encaissement e-commerce',
        body: `Certaines opérations n'ont pas pu être validées et nécessitent votre intervention. <br>
              Validez unitairement les opérations "À valider" restantes.`,
      },
    });
  }

  private async openConfirmationModal() {
    return (
      await lastValueFrom(
        this.modalService.open(ConfirmationModalComponent, {
          data: {
            title: 'Confirmation',
            body: `<b>Valider les opérations de catégorie Encaissement e-commerce</b> ?<br>
        Vous pourrez toujours modifier ces opérations depuis "Opérations validées".`,
            noText: 'Annuler',
            yesText: 'Valider',
            secondaryColor: true,
          },
        }).afterClosed$,
      )
    ).data;
  }

  shouldShowEcommerceCollectionButtons(): void {
    this.shouldFilterOperationSubject.next(true);
    this.metricsService.pushMixpanelEvent('Accounting e-commerce collection validation activated', {
      'CTA Location': 'Operations',
    });
  }

  shouldNotShowEcommerceCollectionButtons(): void {
    this.shouldFilterOperationSubject.next(false);
    this.metricsService.pushMixpanelEvent('Accounting e-commerce collection validation deactivated', {
      'CTA Location': 'Operations',
    });
  }
}
