import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonComponent } from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { AccrualOperationComponentService } from '../../../../services/accrual-operation.component.service';

@Component({
  selector: 'dougs-accrual-operation-cta',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './accrual-operation-cta.component.html',
  styleUrl: './accrual-operation-cta.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccrualOperationCtaComponent {
  constructor(public readonly accrualOperationComponentService: AccrualOperationComponentService) {}

  @Input({ required: true }) operation!: Operation;
}
