import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { distinctUntilKeyChanged, filter, Observable } from 'rxjs';
import { groupByKey } from '@dougs/core/utils';
import { Breakdown, Operation } from '@dougs/operations/dto';
import { OperationService } from '@dougs/operations/shared';
import { OperationDetailsComponentService } from './operation-details.component.service';
import { OperationComponentService } from './operation.component.service';

@Injectable()
export class AccrualOperationComponentService {
  constructor(
    private readonly operationComponentService: OperationComponentService,
    private readonly operationService: OperationService,
    private readonly operationDetailsComponentService: OperationDetailsComponentService,
  ) {}

  operation: WritableSignal<Operation | null> = signal<Operation | null>(null);
  operation$: Signal<Operation | null> = this.operation.asReadonly();

  signedAmount$: Signal<number | null> = computed(() =>
    this.operation$() ? (this.operation$()?.amount || 0) * (this.isInbound$() ? -1 : 1) : null,
  );

  isInbound$: Signal<boolean> = computed(
    () => (this.isCustomerInvoiceOperation$() ? !this.operation$()?.isInbound : this.operation$()?.isInbound) || false,
  );

  private readonly isSalesInvoiceByDougs: WritableSignal<boolean> = signal<boolean>(false);
  isSalesInvoiceByDougs$: Signal<boolean> = this.isSalesInvoiceByDougs.asReadonly();

  operationIdChanged$: Observable<Operation> = toObservable(this.operation$).pipe(
    filter((operation: Operation | null): operation is Operation => !!operation),
    distinctUntilKeyChanged('id'),
  );

  isCustomerInvoiceOperation$: Signal<boolean> = computed(() => this.operation$()?.type === 'invoiceCustomer');

  groupedBreakdowns$: Signal<{ [key: string]: Breakdown[] }> = computed(() => this.handleBreakdowns(this.operation$()));
  totalAmountExcludingFee$: Signal<number> = computed(() => this.getTotalAmountExcludingFee(this.operation$()));
  mainBreakdown$: Signal<Breakdown | null> = computed(() => this.getMainBreakdown(this.operation$()));
  counterpartBreakdown$: Signal<Breakdown | null> = computed(() => this.getCounterpartBreakdown(this.operation$()));
  breakdowns$: Signal<Breakdown[]> = computed(
    () => this.operation$()?.breakdowns?.filter((breakdown) => !breakdown.isCounterpart) || [],
  );

  isUpdatingValidity: WritableSignal<boolean> = signal<boolean>(false);
  isUpdatingValidity$: Signal<boolean> = this.isUpdatingValidity.asReadonly();

  adminPanelOpened: WritableSignal<boolean> = signal<boolean>(false);
  adminPanelOpened$: Signal<boolean> = this.adminPanelOpened.asReadonly();

  setCurrentOperation(operation: Operation): void {
    this.isUpdatingValidity.set(false);
    this.operation.set(operation);
    this.operationComponentService.setCurrentOperation(operation);
  }

  setIsSalesInvoiceByDougs(isSalesInvoiceByDougs: boolean): void {
    this.isSalesInvoiceByDougs.set(isSalesInvoiceByDougs);
  }

  updateAmount(signedAmount: number): void {
    const isInbound: boolean = this.isCustomerInvoiceOperation$() ? signedAmount >= 0 : signedAmount < 0;
    const amount: number = Math.abs(signedAmount);
    this.operationComponentService.updateOperation({ amount, isInbound });
  }

  updateIsInbound(): void {
    const isInbound = !this.operation$()?.isInbound;
    this.operationComponentService.updateOperation({ isInbound });
  }

  updateDate(date: string): void {
    this.operationComponentService.updateOperation({ date });
  }

  updateOperationValidity(validated: boolean): void {
    if (!this.isUpdatingValidity$()) {
      this.isUpdatingValidity.set(true);
      this.operationComponentService.updateOperation({ validated }, true);
      setTimeout(() => this.isUpdatingValidity.set(false), 5000);
    }
  }

  public addBreakdown(operation: Operation, sectionId: string): void {
    if (!this.isUpdatingValidity$()) {
      this.operationDetailsComponentService.onAddBreakdown(operation, sectionId);
    }
  }

  toggleAdminPanel(): void {
    this.adminPanelOpened.update((opened) => !opened);
  }

  private handleBreakdowns(operation: Operation | null): { [key: string]: Breakdown[] } {
    if (operation?.breakdowns?.length) {
      const breakdowns: Breakdown[] = this.operationDetailsComponentService.orderBreakdownByCreation(
        operation.breakdowns,
      );
      return groupByKey((breakdown: Breakdown) => breakdown.section, breakdowns);
    }
    return {};
  }

  private getTotalAmountExcludingFee(operation: Operation | null): number {
    return operation ? this.operationService.getTotalAmountExcludingFee(operation) : 0;
  }

  private getMainBreakdown(operation: Operation | null): Breakdown | null {
    return operation ? this.operationService.getMainBreakdown(operation) : null;
  }

  private getCounterpartBreakdown(operation: Operation | null): Breakdown | null {
    return operation ? this.operationService.getCounterPartBreakdown(operation) : null;
  }
}
