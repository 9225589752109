import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable, withLatestFrom } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { FormService } from '@dougs/core/form';
import { OperationPost } from '@dougs/operations/dto';
import { NotValidatedOperationsStateService, OperationsSearchService } from '@dougs/operations/shared';
import { Currency, SynchronizedAccount } from '@dougs/synchronized-accounts/dto';
import { SynchronizedAccountStateService } from '@dougs/synchronized-accounts/shared';
import { UserStateService } from '@dougs/user/shared';

@Injectable()
export class ManualBankTransactionComponentService {
  formGroup = new FormGroup({
    accountId: new FormControl<number | undefined>(undefined, { validators: [Validators.required], nonNullable: true }),
    date: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    wording: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    amount: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    originalCurrency: new FormControl<Currency>(Currency.EUR, { validators: [Validators.required], nonNullable: true }),
  });

  accountFormControlValueChanges$: Observable<void> = this.accountFormControl.valueChanges.pipe(
    distinctUntilChanged(),
    withLatestFrom(this.synchronizedAccountStateService.synchronizedAccounts$),
    map(([accountId, synchronizedAccounts]) => {
      if (accountId) {
        const account: SynchronizedAccount = synchronizedAccounts.find(
          (acc) => acc.id === accountId,
        ) as SynchronizedAccount;
        const accountCurrency: string = account.currency;
        this.updateCurrencyForm(accountCurrency);
      }
    }),
  );

  showManualBankTransaction$: Observable<boolean> = combineLatest([
    this.userStateService.loggedInUser$,
    this.companyStateService.activeCompany$,
    this.operationsSearchService.hasCurrentSearch$,
  ]).pipe(
    map(
      ([loggedInUser, activeCompany, hasCurrentSearch]) =>
        loggedInUser.isAccountantOrAdmin && !!activeCompany?.isAccountingRecoveryActivated && !hasCurrentSearch,
    ),
  );

  constructor(
    private readonly companyStateService: CompanyStateService,
    private readonly formService: FormService,
    private readonly notValidatedOperationsStateService: NotValidatedOperationsStateService,
    private readonly synchronizedAccountStateService: SynchronizedAccountStateService,
    private readonly userStateService: UserStateService,
    private readonly operationsSearchService: OperationsSearchService,
  ) {}

  get accountFormControl(): FormControl<number | undefined> {
    return this.formGroup.get('accountId') as FormControl<number | undefined>;
  }

  get dateFormControl(): FormControl<string> {
    return this.formGroup.get('date') as FormControl<string>;
  }

  get wordingFormControl(): FormControl<string> {
    return this.formGroup.get('wording') as FormControl<string>;
  }

  get amountFormControl(): FormControl<number> {
    return this.formGroup.get('amount') as FormControl<number>;
  }

  get currencyFormControl(): FormControl<string> {
    return this.formGroup.get('originalCurrency') as FormControl<string>;
  }

  get formLineHasErrorDisplayed(): boolean {
    return (
      this.formService.isControlInvalid(this.dateFormControl) ||
      this.formService.isControlInvalid(this.wordingFormControl) ||
      this.formService.isControlInvalid(this.amountFormControl) ||
      this.formService.isControlInvalid(this.currencyFormControl)
    );
  }

  async createManualOperation(): Promise<void> {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.invalid) {
      const manualOperation: OperationPost = this.formGroup.value as OperationPost;
      await this.notValidatedOperationsStateService.createManualOperation(
        this.companyStateService.activeCompany.id,
        manualOperation,
      );
      this.resetFormGroup();
    }
  }

  private resetFormGroup(): void {
    this.dateFormControl.reset();
    this.amountFormControl.reset();
    this.wordingFormControl.reset();
  }

  updateCurrencyForm(currency: string): void {
    this.currencyFormControl.setValue(currency);
  }
}
