import { inject, QueryList, TemplateRef } from '@angular/core';
import { JoyrideService } from 'ngx-joyride';
import { JoyrideStepInfo } from 'ngx-joyride/lib/models/joyride-step-info.class';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { toPromise } from '@dougs/core/utils';
import { UserStateService } from '@dougs/user/shared';

export interface ProductTourSteps {
  [key: string]: TemplateRef<any>;
}

export abstract class AbstractProductTourService {
  protected readonly logger: LoggerService = inject(LoggerService);
  private readonly joyrideService: JoyrideService = inject(JoyrideService);
  public readonly userStateService: UserStateService = inject(UserStateService);

  private readonly steps: BehaviorSubject<ProductTourSteps | undefined> = new BehaviorSubject<
    ProductTourSteps | undefined
  >(undefined);
  steps$: Observable<ProductTourSteps | undefined> = this.steps.asObservable();

  private readonly buttons: BehaviorSubject<ProductTourSteps | undefined> = new BehaviorSubject<
    ProductTourSteps | undefined
  >(undefined);
  buttons$: Observable<ProductTourSteps | undefined> = this.buttons.asObservable();

  protected abstract callbackNextJoyride(joyrideStepInfo: JoyrideStepInfo): void;
  protected abstract callbackErrorJoyride(err: Error, currentStep: number): void;
  protected abstract callbackCompleteJoyride(currentStep: number): void;

  addSteps(templateRefs: QueryList<TemplateRef<any>>): void {
    this.steps.next(
      templateRefs.toArray().reduce((acc, templateRef) => {
        return {
          ...acc,
          [templateRef.elementRef.nativeElement.parentElement.id]: templateRef,
        };
      }, {}),
    );
  }

  addButtons(templateRefs: QueryList<TemplateRef<any>>): void {
    this.buttons.next(
      templateRefs.toArray().reduce((acc, templateRef) => {
        return {
          ...acc,
          [templateRef.elementRef.nativeElement.parentElement.id]: templateRef,
        };
      }, {}),
    );
  }

  async startTour(): Promise<void> {
    if (
      this.userStateService.activeUser.metadata.hasSeenProductTour ||
      this.userStateService.loggedInUser.isAccountantOrAdmin
    ) {
      return;
    }

    const steps: ProductTourSteps | undefined = await toPromise(this.steps$);

    if (steps) {
      if (Object.keys(steps).length !== 0) {
        let currentStep = 1;
        const joyrideSubscription: Subscription = this.joyrideService
          .startTour({
            themeColor: '#000000',
            stepDefaultPosition: 'top',
            steps: Object.keys(steps),
          })
          .subscribe({
            next: (joyrideStepInfo: JoyrideStepInfo) => {
              currentStep = joyrideStepInfo.number;
              this.callbackNextJoyride(joyrideStepInfo);
            },
            error: (err: Error) => {
              this.callbackErrorJoyride(err, currentStep);
              joyrideSubscription.unsubscribe();
            },
            complete: () => {
              this.callbackCompleteJoyride(currentStep);
              joyrideSubscription.unsubscribe();
            },
          });
      }
    }
  }
}
