<ng-container *ngIf="baseOperationsServiceService.autoScrollOnOperationListChange$ | async"></ng-container>
<ng-container *ngIf="baseOperationsServiceService.loadMoreOperations$ | async"></ng-container>

<div class="header-page">
  <h2>
    <ng-content></ng-content>
  </h2>
  <dougs-operations-ecommerce-collection-actions
    *ngIf="showEcommerceActions && (operationsEcommerceCollectionActionsService.shouldShowEcommerceCollectionButtons$ | async)"
    class="ecommerce-buttons mr-16"
    [ngClass]="{ 'mr-16 ecommerce-buttons': operations?.length > 0 }"
  ></dougs-operations-ecommerce-collection-actions>
  <div class="operation-list__reducer mr-4" *ngIf="operations?.length > 0">
    <span class="operation-list__reducer__pointer" (click)="handleListReduction()">
      <span class="mr-8 hide-on-mobile">{{ listIsReduced ? 'Etendre' : 'Réduire' }}</span>
      <i [ngClass]="{ 'fa-chevron-down': listIsReduced, 'fa-chevron-up': !listIsReduced }" class="fal"></i>
    </span>
  </div>
</div>
<div
  *ngIf="operations?.length > 0 || ((operationsEcommerceCollectionActionsService.shouldShowBlankSlate$ | async) && showEcommerceActions)"
  class="operation-list"
  [immediateCheck]="true"
  [infiniteScrollDisabled]="listIsReduced"
  infiniteScroll
  (scrolled)="baseOperationsServiceService.onScroll()"
>
  <p
    *ngIf="(operationsEcommerceCollectionActionsService.shouldShowBlankSlate$ | async) && showEcommerceActions"
    class="px-16 py-16 small center"
  >
    Aucune opération à valider
  </p>
  <ng-container
    *ngFor="let operation of currentOperations; let index = index; let isFirst = first; trackBy: trackByOperation"
  >
    <div
      class="px-24 py-8 operation-list__date"
      *ngIf="operations | showOperationDivider: index:isFirst"
      [ngStyle]="{ top: '0px' }"
    >
      {{ operation.date | date: 'MMMM yyyy' | titlecase }}
    </div>
    <dougs-operation
      *ngIf="isFirst && operationType === 'notValidated'"
      joyrideStep="operation"
      productTour
      [productTourContent]="(accountingProductsTourService.steps$ | async)?.['operation']"
      [productTourNextTemplate]="(accountingProductsTourService.buttons$ | async)?.['nextButton']"
      [synchronizedAccounts]="synchronizedAccounts"
      [showOperationDetails]="openedOperationId === operation.id"
      (openOperationDetails)="onOpenOperationDetails($event)"
      [operation]="operation"
    ></dougs-operation>

    <dougs-operation
      *ngIf="!isFirst || operationType !== 'notValidated'"
      [synchronizedAccounts]="synchronizedAccounts"
      [showOperationDetails]="openedOperationId === operation.id"
      (openOperationDetails)="onOpenOperationDetails($event)"
      [operation]="operation"
    ></dougs-operation>
  </ng-container>
  <div class="operation-list__show-all" *ngIf="listIsReduced && operations.length > 3" (click)="handleListReduction()">
    <span class="operation-list__show-all__label mt-8 mb-8">Tout afficher</span>
    <i class="fas fa-chevron-down ml-8"></i>
  </div>
</div>
