import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { JoyrideModule } from 'ngx-joyride';
import { ButtonComponent } from '@dougs/ds';
import { AccountingProductsTourService } from '@dougs/revenue/products-tour/shared';
import { ProductTourDirective } from '../../directives/product-tour.directive';
import { CounterTemplateComponent } from '../counter-template/counter-template.component';
import { DoneButtonComponent } from '../done-button/done-button.component';
import { NextButtonComponent } from '../next-button/next-button.component';
import { NextFirstButtonComponent } from '../next-first-button/next-first-button.component';
import { PreviousButtonComponent } from '../previous-button/previous-button.component';
import { ProductTourTemplateCreationMeetingComponent } from '../product-tour-templates/creation-meeting/product-tour-template-creation-meeting.component';
import { ProductTourTemplateEndComponent } from '../product-tour-templates/end/product-tour-template-end.component';
import { ProductTourTemplateOperationComponent } from '../product-tour-templates/operation/product-tour-template-operation.component';
import { ProductTourTemplateShortcutComponent } from '../product-tour-templates/shortcut/product-tour-template-shortcut.component';
import { ProductTourTemplateWelcomeDemoComponent } from '../product-tour-templates/welcome-demo/product-tour-template-welcome-demo.component';

@Component({
  selector: 'dougs-product-tour-accounting-without-siren-demo',
  standalone: true,
  imports: [
    CommonModule,
    JoyrideModule,
    ButtonComponent,
    CounterTemplateComponent,
    NextButtonComponent,
    NextFirstButtonComponent,
    PreviousButtonComponent,
    DoneButtonComponent,
    ProductTourDirective,
    ProductTourTemplateWelcomeDemoComponent,
    ProductTourTemplateOperationComponent,
    ProductTourTemplateShortcutComponent,
    ProductTourTemplateCreationMeetingComponent,
    ProductTourTemplateEndComponent,
  ],
  templateUrl: './product-tour-accounting-without-siren-demo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductTourAccountingWithoutSirenDemoComponent {
  constructor(public readonly accountingProductsTourService: AccountingProductsTourService) {}

  @ViewChildren('content')
  set content(content: QueryList<TemplateRef<any>>) {
    this.accountingProductsTourService.addSteps(content);
  }

  @ViewChildren('button')
  set button(button: QueryList<TemplateRef<any>>) {
    this.accountingProductsTourService.addButtons(button);
  }
}
