import { Pipe, PipeTransform } from '@angular/core';
import { Pill } from '@dougs/ds';

@Pipe({
  name: 'invoiceOperationStatusPill',
  standalone: true,
})
export class InvoiceOperationStatusPillPipe implements PipeTransform {
  transform(status?: string): Pill {
    if (status?.includes('pending')) {
      return 'primary';
    } else if (status?.includes('overPaid') || status?.includes('paid')) {
      return 'success';
    } else if (status?.includes('partiallyPaid')) {
      return 'warning';
    }
    return 'primary';
  }
}
