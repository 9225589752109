<div class="mt-24" *ngIf="manualBankTransactionComponentService.showManualBankTransaction$ | async">
  <ng-container *ngIf="manualBankTransactionComponentService.accountFormControlValueChanges$ | async"></ng-container>
  <h2 class="color-admin mb-8">Saisie manuelle</h2>
  <form
    [formGroup]="manualBankTransactionComponentService.formGroup"
    autocomplete="off"
    class="form-container"
    (ngSubmit)="manualBankTransactionComponentService.createManualOperation()"
  >
    <dougs-form-field>
      <dougs-select formControlName="accountId" dougsFormFieldControl placeholder="Choisir un compte bancaire">
        <dougs-select-option
          *ngFor="let account of synchronizedAccountStateService.synchronizedAccounts$ | async; trackBy: 'id' | trackBy"
          [value]="account.id"
        >
          {{
            account.accountingNumber +
              ' - ' +
              account.accountName +
              ' - ' +
              account.currency +
              ' (' +
              account.number +
              ')'
          }}
        </dougs-select-option>
      </dougs-select>
      <span
        *ngIf="formService.isControlInvalid(manualBankTransactionComponentService.accountFormControl)"
        dougsFormFieldError
      >
        <span *ngIf="manualBankTransactionComponentService.accountFormControl.hasError('required')">
          Ce champ est requis
        </span>
      </span>
    </dougs-form-field>
    <div class="form-field-line">
      <dougs-input-datepicker [noMargin]="true" class="mr-8" formControlName="date"></dougs-input-datepicker>
      <dougs-form-field [noMargin]="true" class="mr-8">
        <label dougsFormFieldLabel for="wording">Intitulé</label>
        <input
          type="text"
          autocomplete="on"
          placeholder="Intitulé"
          id="wording"
          formControlName="wording"
          dougsFormFieldControl
        />
        <span
          *ngIf="formService.isControlInvalid(manualBankTransactionComponentService.wordingFormControl)"
          dougsFormFieldError
        >
          <span *ngIf="manualBankTransactionComponentService.wordingFormControl.hasError('required')">
            Ce champ est requis
          </span>
        </span>
      </dougs-form-field>
      <dougs-form-field [noMargin]="true" class="mr-8">
        <label dougsFormFieldLabel for="amount">Montant</label>
        <input
          type="number"
          step="0.01"
          id="amount"
          placeholder="Montant"
          formControlName="amount"
          dougsFormFieldControl
        />
        <span
          *ngIf="formService.isControlInvalid(manualBankTransactionComponentService.amountFormControl)"
          dougsFormFieldError
        >
          <span *ngIf="manualBankTransactionComponentService.amountFormControl.hasError('required')">
            Ce champ est requis
          </span>
        </span>
      </dougs-form-field>
      <dougs-form-field [noMargin]="true" class="mr-8">
        <label dougsFormFieldLabel for="originalCurrency">Devise</label>
        <input
          [dougsDropdownTrigger]="currencyDropdown"
          type="text"
          placeholder="Devise"
          minlength="3"
          maxlength="3"
          id="originalCurrency"
          formControlName="originalCurrency"
          dougsFormFieldControl
        />
        <dougs-dropdown #currencyDropdown>
          <dougs-dropdown-option
            *ngFor="let currency of currencyList | keyvalue"
            [closeOnClick]="true"
            (click)="manualBankTransactionComponentService.updateCurrencyForm(currency.value)"
          >
            {{ currency.value }}
          </dougs-dropdown-option>
        </dougs-dropdown>
        <span
          *ngIf="formService.isControlInvalid(manualBankTransactionComponentService.currencyFormControl)"
          dougsFormFieldError
        >
          <span *ngIf="manualBankTransactionComponentService.currencyFormControl.hasError('required')">
            Ce champ est requis
          </span>
        </span>
      </dougs-form-field>
      <dougs-button
        [ngClass]="{ 'no-submit-margin': manualBankTransactionComponentService.formLineHasErrorDisplayed }"
        class="form__submit mt-16"
        type="submit"
      >
        Ajouter
      </dougs-button>
    </div>
  </form>
</div>
